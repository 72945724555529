import React from 'react'
import {Center} from '@chakra-ui/react'
function AboutUs() {
  return (
    <Center>
    <div className='text-4xl text-skyBlue font-bold'>Së shpejti!</div>
    </Center>
  )
}

export default AboutUs