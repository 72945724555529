import React from 'react'
import Footer from '../components/Footer'

function PrivacyPolicy() {
  return (
    <>
    <div className='flex justify-start items-center flex-wrap'>
<div className='flex mt-12 w-full text-4xl text-darkBlue bg-opacity-30 font-semibold p-14 bg-[#bce0fa] justify-center items-center'>Politikat e Privatësisë</div>
<div className='flex-col  mt-8 items-start justify-start p-8'>
    <h1 className='text-3xl font-bold'>1. Informacioni i marrë dhe përdorimi i tij</h1>
    <p className='pt-8'>Ne mbledhim dhe përdorim informacionin tuaj personal vetëm në raste kur është e nevojshme për t'ju ofruar shërbime të përshtatshme në platformën Pacienti. Ky informacion mund të përfshijë emrin, mbiemrin, datën e lindjes, numrin e identifikimit personal, adresën e emailit, numrin e telefonit dhe informacione të tjera të ngjashme.</p>
</div>
<div className='flex-col  items-start justify-start p-8'>
    <h1 className='text-3xl font-bold'>2. Përdorimi i të dhënave personale</h1>
    <p className='font-semibold text-darkBlue pt-8'>Të dhënat tuaja personale mund të përdoren për qëllime të ndryshme, duke përfshirë:</p>
    <br></br>
    <ul className='list-disc pl-6'>
        <li>Identifikimin tuaj si përdorues i platformës Pacienti</li>
        <li>Përmirësimin e shërbimeve tona për të ofruar një përvojë më të mirë përdoruesi</li>
        <li>Komunikimin me ju në lidhje me shërbimet tona</li>
        <li>Mbledhjen e informacionit statistikor dhe analitik për t'i përdorur në qëllime të zhvillimit të platformës dhe shërbimeve tona.</li>
    </ul>
</div>
<div className='flex-col  items-start justify-start p-8'>
    <h1 className='text-3xl font-bold'>3. Siguria</h1>
    <p className='pt-8'>Ne nuk do të ndajmë asnjë informacion personal me palë të treta pa lejen tuaj të qartë. Mund të ndajmë informacionin tuaj personal me partnerët tanë të biznesit në raste kur kjo është e nevojshme për të ofruar shërbime specifike. Përveç kësaj, mund të ndajmë informacionin tuaj personal nëse kërkohet nga autoritetet e ligjit në Kosovë.</p>
</div>
<div className='flex-col  items-start justify-start p-8'>
    <h1 className='text-3xl font-bold'>4. Cookies dhe teknologji të ngjashme</h1>
    <p className='pt-8'>Ne mund të përdorim cookies dhe teknologji të ngjashme për të mbledhur informacione statistikore dhe analitike në lidhje me përdorimin e platformës Pacienti. Këto informacione mund të përdoren për të përmirësuar shërbimet tona dhe për të ofruar përvojën më të 
mirë përdoruesi. Ju keni opsionin për të kontrolluar përdorimin e cookies duke ndryshuar cilësimet në shfletuesin tuaj.</p>
</div>

<div className='flex-col  items-start justify-start p-8'>
    <h1 className='text-3xl font-bold'>5. Ndryshime në Politikën e Privatësisë</h1>
    <p className='pt-8'>Ne mund të ndryshojmë Politikën tonë të Privatësisë nga koha në kohë për të përshtatur ndryshime në shërbimet tona dhe ligjet në fuqi në Kosovë. Çdo ndryshim në Politikën tonë të Privatësisë do të jetë në dispozicion në website dhe ne do të njoftojmë përdoruesit tanë në rast se ndryshimet janë të rëndësishme.</p>
</div>
<div className='flex-col  items-start justify-start p-8'>
    <h1 className='text-3xl font-bold'>6. Aksesimi dhe kontrolli i të dhënave personale</h1>
    <p className='pt-8'>Ju keni të drejtën të kërkoni kopjen tuaj të të dhënave personale që mbahen nga ne. Ju gjithashtu keni të drejtën të kërkoni që të dhënat tuaja personale të jenë të sakta dhe të ndryshohen në rast se nuk janë të sakta. Ju mund të kërkoni fshirjen e të dhënave tuaja personale në raste të caktuara. Për të kryer këto kërkesa, ju lutemi na kontaktoni në adresën e emailit tonë <b>info@pacientii.com</b></p>
</div>
<div className='flex-col  items-start justify-start p-8'>
    <h1 className='text-3xl font-bold'>7. Kontakti</h1>
    <p className='pt-8'>Nëse keni ndonjë pyetje në lidhje me Politikën tonë të Privatësisë, ju lutemi na kontaktoni në adresën e emailit tonë <b>info@pacientii.com</b>. Ju gjithashtu mund të na kontaktoni nëse keni ndonjë kërkesë në lidhje me të dhënat tuaja personale të mbledhura nga ne. Ne do të përgjigjemi sa më shpejt që të jetë e mundur.</p>
</div>
<div className='flex-col  items-start justify-start p-8'>
    <h1 className='text-3xl font-bold'>8. Përmbledhje</h1>
    <p className='pt-8'>Ne i mbajmë të dhënat tuaja personale të sigurta dhe respektojmë privatësinë tuaj. Ne përdorim të dhëna personale vetëm për qëllime të përcaktuara dhe në mënyrë të përputhur me ligjin në fuqi në Kosovë. Ne mund të ndryshojmë Politikën tonë të Privatësisë nga koha në kohë për të përshtatur ndryshime në shërbimet tona dhe ligjet në fuqi në Kosovë, por do të na bëhet detyrë t'ju njoftojmë për ndryshimet e rëndësishme.<br/> <br/>
    Ju keni të drejtën të kontrolloni dhe të kërkojnë akses në të dhënat personale që mbahen nga ne, si dhe të kërkoni fshirjen e tyre në raste të caktuara. Ne jemi të gatshëm të bashkëpunojmë me ju për t'i dhënë përgjigje pyetjeve tuaja në lidhje me të dhënat tuaja personale dhe Politikën tonë të Privatësisë.<br/> <br/>
    <b>Faleminderit që përdorni platformën tonë për menaxhimin e pacientëve.</b>
    </p>
</div>

    </div>
    <Footer/>
    </>
  )
}

export default PrivacyPolicy