import React,{useState,useEffect, Fragment} from 'react'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {Button} from '@chakra-ui/react'
import Loader from './Loader';
export default function DoctorInfo() {
  const [loader,setLoader] = useState(false)
     const [doctorInfo,setDoctorInfo] = useState([]);
   const params = useParams();
    const getDoctorData = async () => {
      try {
        console.log(params.id)
        const response = await axios.get(
          `https://pacienti-home.vercel.app/api/doctor/get-doctor/${params.id}`,
        );
        
        if (response.data.success) {
          setLoader(true)
         setDoctorInfo(response.data.data);
        }
      } catch (error) {
       
      }
      
    };
    useEffect(() => {
      getDoctorData();
     })
  return (
    <>
    {loader ? doctorInfo.map((doctor) => {
      return (
 <div className='p-8'>
  <div className='flex flex-wrap justify-between items-start'>
      <div className="px-4 sm:px-0">
        <h1 className="text-xl font-semibold leading-7 text-gray-900">Dr. {doctor?.firstName + " " + doctor?.lastName}</h1>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Informacione rreth mjekut</p>
      </div>
      <Button as="a" href={`tel:+383${doctor?.phoneNumber}`} fontSize={'bold'} color={'white'} background={'#2CB9FF'}>Kontakto</Button>
      </div>
      <div className="mt-6 border-t border-skyBlue">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm leading-6 text-gray-900">Emri dhe Mbiemri</dt>
            <dd className="mt-1 text-sm leading-6 font-medium text-darkBlue sm:col-span-2 sm:mt-0">{doctor?.firstName + " " + doctor?.lastName}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Specializimi</dt>
            <dd className="mt-1 text-sm font-medium  leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{doctor?.specialization}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Vendi i punës</dt>
            <dd className="mt-1 text-sm font-medium  leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{doctor?.workPlace}</dd>
          </div>
  
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Numri kontaktues</dt>
            <dd className="mt-1 text-sm font-medium  leading-6 text-gray-700 sm:col-span-2 sm:mt-0">383 - {doctor?.phoneNumber}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">E-mail adresa</dt>
            <dd className="mt-1 text-sm font-medium  leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{doctor?.email}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Orari i punës</dt>
            <dd className="mt-1 text-sm font-medium  leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{doctor?.isOpen}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Edukimi</dt>
            <dd className="mt-1 text-sm font-medium  leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
             {doctor?.education}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Eksperienca</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{doctor?.experience}</dd>
          </div>


          
        
        </dl>
      </div>
    </div>
      )
    }) : <Loader/>}
   
    </>
  )
}
