import React from 'react'
import { Image,Box,Flex,Link,Center, Text, Button} from '@chakra-ui/react'
import { css, keyframes } from '@emotion/react';
import IntroductionImage from '../assets/Intro3.png'
import FeaturesCard from '../components/FeaturesCard'
import {CgFileDocument} from 'react-icons/cg'
import {FiUsers} from 'react-icons/fi'
import HowItWorks1 from '../assets/FirstTutorial.png'
import {FaUserAlt} from 'react-icons/fa'
import HowItWorks from '../assets/Comp 1_3 2.mov'
import {GrMail} from 'react-icons/gr'
import HowItWorks3 from '../assets/hwitwrks3.png'
import {GiPartyPopper} from 'react-icons/gi'
import Doctors from '../components/Doctors';
import Footer from '../components/Footer';
// #17153b - dark blue
// #2CB9FF - blue
function HomeScreen() {
  

  
  const fadeInOut = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;

const boxStyle = css`
  animation: ${fadeInOut} 2.5s linear infinite;
`;
  return (
    <>
    {/* THE INTRODUCTION */}
    <div className='flex-col overflow-hidden text-[#17153b] bg-[#EFF2F9]  justify-center items-center'>
<div className='section-one flex-col justify-center items-center '>
<h1 className='text-[#17153b]  text-center font-bold pt-24 md:text-7xl text-6xl  '>Menaxho <span className='text-[#2CB9FF]'>pacientët</span><br></br> lehtë dhe shpejtë.</h1>
<h1 className='text-center pt-10 font-normal  text-[#1E2531] text-xl'>Platforma jonë është krijuar për t'ju ndihmuar të menaxhoni të dhënat shëndetësore të pacientëve tuaj.</h1>
<Center mt={9}><Button   className='sm:scale-125 scale-100'  _hover={'none'} background={'#2CB9FF'} color={'white'}><Link href='https://app.pacientii.com/register'>Regjistrohu</Link></Button>
<Button  className='sm:ml-14 ml-3 sm:scale-125 scale-100 no-underline'  _hover={'none'} background={'#17153b'} color={'white'} ><Link textDecoration={'none'} href='/intro'>Ç'është pacienti?</Link></Button>
</Center>
<Center ><Image className='md:scale-90 scale-110  md:pt-0 pt-12'   w={'auto'}  src={IntroductionImage}/></Center>

{/* THE MAIN FUNCTIONALITIES */}
</div>
<div className='flex-col bg-white    justify-between   items-center'>
 <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-[#2CB9FF]">Me sistemin tonë dixhital</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          90% e punës do të bëhet më e lehtë.
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
          Përqendrohuni në atë që është me rëndësi të vërtetë, ofrimin e kujdesit më të mirë të mundshëm për pacientin tuaj.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
          <FeaturesCard hrefColor='#2CB9FF' color='gray.200'  backgroundColor='#17153b' title='Ruaj të dhënat shëndetësore të pacientit' content='Platforma jonë ruan me siguri të gjitha informacionet e pacientëve tuaj, duke përfshirë anamnezën, terapinë, datën e vizitës, si dhe informacionet e tjera relevante.' icon={<CgFileDocument className='ml-2 mt-2' size={38} color='#2CB9FF'/>}/>
        <FeaturesCard padding='6' titleColor='#17153b' title='Pacientët mund të ju kontaktojnë për të bërë termine mjekësore'  content='Profili juaj do të theksojë kualifikimet tuaja, përvojën dhe vlerësimet e pacientëve në faqën zyrtare të platformës Pacienti.' icon={<FiUsers className='ml-2 mt-2' size={32} color='#17153b'/>} hrefColor='#2CB9FF' backgroundColor='#ECF1F6'/>
          </dl>
        </div>
      </div>
    </div>

{/* FIRST TUTORIAL */}
<div className='flex-col bg-white   justify-evenly items-center '>
<h1 className='font-bold md:text-5xl text-4xl md:text-center text-center md:pl-0 pl-4'>Si funksionon?</h1>
<div className='  flex flex-wrap md:pt-24 pt-12  justify-center lg:space-x-8 space-x-0 items-center'>
  <Image   w={'650px'} src={HowItWorks1}/>
  <div className='flex-wrap xl:pt-0 max-w-[450px] pt-12 pl-6 sm:pl-0 flex-col  justify-start  items-center text-left text-[#17153b]'>
<h1 className='font-semibold justify-start   flex md:text-4xl text-3xl'><Box css={boxStyle}><FaUserAlt  className='pr-3 hidden md:flex transition-opacity from-current  mr-2 pt-1' color='#2CB9FF'/></Box> Regjistrohu si <br></br> specialist mjekësor</h1>
<p className='lg:pl-10 pt-8 leading-relaxed text-lg font-normal text-gray-600'>Hapi i parë për të u regjistruar si specialist mjekësor në platformën Pacienti është të i shkruani të dhënat tuaja personale si <b>email adresën</b> në të cilën do e pranoni një email verifikues për të e mundësuar kycjen ne platformë.<br></br> </p>
</div>
</div>
</div>

{/* SECOND TUTORIAL */}
<div className=' mt-12 bg-white  flex flex-wrap-reverse  justify-evenly items-center'>
 
  <div className=' flex-col md:pt-0 pt-8  justify-start items-center text-left text-[#17153b]'>
<h1 className='font-semibold flex md:text-4xl text-3xl'><GrMail className='pr-3 transition-opacity hidden md:flex from-current  mr-2 lg:pt-1 pt-1' color='#2CB9FF'/> Kontrolloni<br></br>email adresën tuaj</h1>
<p className='lg:pl-10 pt-8 leading-relaxed text-lg font-medium text-gray-600'>Kontrolloni email adresën tuaj përfshirë<br></br> <b>Spam folderin</b> për emalin verifikues<br></br> që do e pranoni pas plotësimit<br></br> të hapit të parë të regjistrimit.<br></br> Emaili verifikues përmban numrin e <br></br> <b>llogarisë IBAN</b> në të cilën <br></br> pagesa mujore për përdorimin e platformës<br></br> duhet të realizohet. <br></br> </p>
</div>
<Box w={'auto'}>
<video width='570px' loop autoPlay muted>
  <source src={HowItWorks}/>
</video>
</Box>
</div>

{/* THE THIRD TUTORIAL PART */}
<div className='mt-12 bg-white pr-12 flex flex-wrap  justify-around items-center'>
  <Image   w={'650px'} src={HowItWorks3}/>
  <div className='  flex-col justify-start items-center text-left text-[#17153b]'>
<h1 className='font-semibold flex md:text-4xl text-3xl'><Box ><GiPartyPopper  className='pr-3 w-16 transition-opacity from-current  mr-2 pt-1' color='#2CB9FF'/></Box>Kyçuni në<br></br>llogarinë tuaj</h1>
<p className='pl-16 pt-8 leading-relaxed text-lg font-medium text-gray-600'>Pasi që të e realizoni pagesën në IBAN-in e dhënë, <br></br> ju  do të keni kycje të plotë  në llogarinë e <br></br> përdoruesit të platformës pacienti.<br></br> </p>
</div>
</div>

{/* OUR DOCTORS PART */}
<Flex background={'white'} mt={14} flexDir={'column'} justify={'center'} alignItems={'center'}>
<Text fontSize={'5xl'} fontWeight={'bold'} color={'#2CB9FF'}>Specialistët <span className='text-darkBlue'>tanë</span></Text>
<h1 className='text-center pt-6 font-medium text-gray-500 text-lg'>Çdo specialist ka përvojën dhe njohuritë e tyre unike për t'u përkushtuar pacientëve të tyre <br></br> dhe për të iu siguruar trajtimin më të mirë të mundshëm."</h1>
<Doctors/>
</Flex>

{/* WHAT ARE YOU WAITING PART */}
<Flex background={'white'} pt={10} flexDir={'column'}  justify={'center'} alignItems={'center'}>
<Text fontSize={'5xl'} fontWeight={'bold'} color={'#17153b'}>Çfarë po pret?</Text>
<Button size={'lg'} rounded={'xl'} shadow={'xl'} background={'#2CB9FF'} color={'white'} fontWeight={'semibold'} mt={6}><Link href='https://app.pacientii.com/register'>Regjistrohu</Link></Button>
</Flex>
<Footer/>
</div>
    </div>
    </>
  )
}

export default HomeScreen