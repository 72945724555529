import { useState, useEffect } from 'react'
import { Dialog, Popover } from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'

import Logo from '../assets/logo1-removebg.png';
import mobileLogo from '../assets/logoProfile2.png'
import { Outlet } from 'react-router-dom';
import { Button } from '@chakra-ui/react';


export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [showShadow, setShowShadow] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 0) {
        setShowShadow(true);
      } else {
        setShowShadow(false);
      }
    }
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  return (
    <>
    <header className={`bg-white ${showShadow ? 'shadow-md' : ''} transition-all sticky top-0 font-sans pt-4 z-10`}>
      <nav className="mx-auto  flex  max-w-screen-xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <a href='/' className="-m-1.5 p-1.5">
        <img className="h-10 w-auto" src={Logo} alt="" />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          
          <a href="doctors" className="text-md font-medium leading-6 text-gray-900">
            Mjekët
          </a>
          <a href="aboutUs" className="text-md font-medium leading-6 text-gray-900">
            Rreth Nesh
          </a>
          <a href="contact" className="text-md font-medium leading-6 text-gray-900">
            Kontakt
          </a>
        </Popover.Group>
        <div className="hidden items-center lg:flex lg:flex-1 lg:justify-end">
         <a href="https://app.pacientii.com/login" className="pr-6  text-md font-semibold leading-6 text-[#2CB9FF]">
            Kyçu
          </a>
          <Button _hover={'none'} background={'#2CB9FF'} color={'white'}> <a href="https://app.pacientii.com/register" className="  text-md font-bold leading-6">
            Regjistrohu
          </a>
          </Button>
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5">
              <img
                className="h-8 w-auto"
                src={mobileLogo}
                alt=""
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                
                <a
                  href="/doctors"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Specialistët
                </a>
                <a
                  href="/aboutUs"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Rreth Nesh
                </a>
                <a
                  href="/contact"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Kontakt
                </a>
              </div>
              <div className="py-6">
              <a href="https://app.pacientii.com/register" className=" pr-4 text-md font-bold leading-6 text-skyBlue">
            Regjistrohu
          </a>
                <a
                  href="https://app.pacientii.com/login"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Kyçu
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
    <Outlet/>
    </>
  )
}
