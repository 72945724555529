import React from 'react'
import Doctor from './Doctor'
import { Flex,Text,Link} from '@chakra-ui/react'
import axios from 'axios';
import { useEffect, useState, Fragment } from 'react';
function Doctors() {

  const [doctor,setDoctor] = useState([]);
  const getDoctorData = async () => {
    try {
      
      const response = await axios.get(
        "https://pacienti-home.vercel.app/api/doctor/get-doctors",
      );
      
      if (response.data.success) {
       setDoctor(response.data.data);
      }
    } catch (error) {
     
    }
    
  };
  useEffect(() => {
    getDoctorData();
   },[])
  return (
    <>
    <Flex flexDir={'column'} justify={'center'} alignItems={'center'}>
 <div class="grid md:pl-8 mt-12   gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:grid-cols-1 grid-cols-1">
 {doctor?.map((doctors) => {
  return(
    <Fragment key={doctors?._id}>
    <Doctor id={doctors?._id} firstName={doctors?.firstName} lastName={doctors?.lastName} specialization={doctors?.specialization}/>
    </Fragment>
  )
 })}
 
</div>
<Link href='doctors'><Text pt={6} fontWeight={'bold'} fontSize={'xl'} color={'#2CB9FF'}> Shiko më shumë &rarr;</Text></Link>
</Flex>
    </>
  )
}

export default Doctors