import React from 'react'
import {Heading} from '@chakra-ui/react'
import { Flex} from '@chakra-ui/react'
import axios from 'axios';
import { useEffect, useState, Fragment } from 'react';
import Doctor from '../components/Doctor';
import Loader from '../components/Loader';
function DoctorsPage() {
    const [doctor,setDoctor] = useState([]);
    const [loader,setLoader] = useState(false)
    const [searchTerm, setSearchTerm] = useState("");
    // const BaseUrl = 'https://pacienti-home.vercel.app'
    const getDoctorData = async () => {
      try {
        
        const response = await axios.get(
          `https://pacienti-home.vercel.app/api/doctor/get-doctors`,
        );
        
        if (response.data.success) {
          setLoader(true)
         setDoctor(response.data.data);
        }
      } catch (error) {
       
      }
      
    };
    useEffect(() => {
      getDoctorData();
     },[doctor])
  return (
   <div className=' p-10 pt-18 flex-col justify-center items-start'>
<div className='pb-2 flex-wrap  shadow-xl flex justify-around items-center'>
<Heading fontSize={'6xl'} className='  text-darkBlue font-semibold'>Specialistët</Heading>
<div className="flex space-x-0 sm:space-x-8 space-y-4 sm:space-y-0 pt-4 sm:pt-0 flex-wrap justify-center items-center">
            <input
              type="search"
              name=""
              id=""
              placeholder="Kërko..."
              onChange={(e) => setSearchTerm(e.target.value)}
              className="rounded p-1 bg-white placeholder-[#39B6FF] shadow-xl focus:outline-none  focus:border-none transition-all   "
            />
            </div>
</div>
<div className='flex justify-center items-center'>
<>
    <Flex flexDir={'column'} justify={'center'} alignItems={'center'}>
 <div class="grid md:pl-8 mt-12   gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:grid-cols-1 grid-cols-1">
 {loader ? doctor.filter((doctorr) => {
    if(searchTerm === ""){
        return doctorr;
    }else if(
        doctorr.firstName.toLowerCase().includes(searchTerm.toLocaleLowerCase())
        ||
                    doctorr.lastName
                      .toLowerCase()
                      .includes(searchTerm.toLocaleLowerCase())
    ){
        return doctorr;
    }
 }).map((doctors) => {
  return(
    <Fragment key={doctors?._id}>
    <Doctor id={doctors?._id} firstName={doctors?.firstName} lastName={doctors?.lastName} specialization={doctors?.specialization}/>
    </Fragment>
  )
 }) : <Loader/>}
 
</div>
</Flex>
    </>
</div>
   </div>
  )
}

export default DoctorsPage
