import {
    Box,
    chakra,
    Container,
    Link,
    SimpleGrid,
    Stack,
    Text,
    VisuallyHidden,
    Input,
    IconButton,
    useColorModeValue,
    Image,
  } from '@chakra-ui/react';
  import { ReactNode } from 'react';
  import { FaInstagram, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
  import { BiMailSend } from 'react-icons/bi';
  import PacientiLogo from '../assets/shortLogo.png'
  
  const SocialButton = ({
    children,
    label,
    href,
  }) => {
    return (
      <chakra.button
        bg={useColorModeValue('#2CB9FF', 'white')}
        color={'white'}
        rounded={'full'}
        w={8}
        h={8}
        cursor={'pointer'}
        as={'a'}
        href={href}
        display={'inline-flex'}
        alignItems={'center'}
        justifyContent={'center'}
        transition={'background 0.3s ease'}
        _hover={{
          bg: useColorModeValue('#2CB9FF', 'whiteAlpha.200'),
        }}>
        <VisuallyHidden>{label}</VisuallyHidden>
        {children}
      </chakra.button>
    );
  };
  
  const ListHeader = ({ children }) => {
    return (
      <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
        {children}
      </Text>
    );
  };
  
  export default function Footer() {
    return (
      <Box
      mt={20}
        bg={useColorModeValue('gray.50', 'gray.900')}
        color={useColorModeValue('gray.700', 'gray.200')}>
        <Container as={Stack} maxW={'6xl'} py={10}>
          <SimpleGrid
            templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 2fr' }}
            spacing={8}>
            <Stack spacing={6}>
              <Box>
                <Image w={'70px'} src={PacientiLogo}/>
              </Box>
              <Text fontSize={'sm'}>
                © 2024 Pacienti. All rights reserved
              </Text>
              <Stack direction={'row'} spacing={6}>
                <SocialButton  label={'Twitter'} href={'#'}>
                  <FaTwitter color='white'   />
                </SocialButton>
                <SocialButton color='white' label={'YouTube'} href={'https://www.linkedin.com/company/pacienti/'}>
                  <FaLinkedinIn />
                </SocialButton>
                <SocialButton color='white' label={'Instagram'} href={'https://www.instagram.com/pacienti.ks/'}>
                  <FaInstagram />
                </SocialButton>
              </Stack>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>Kompania</ListHeader>
              <Link href={'aboutUs'}>Rreth nesh</Link>
              <Link href={'#'}>Blog</Link>
              <Link href={'contact'}>Kontakt</Link>
              <Link href={'#'}>Pagesa</Link>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>Support</ListHeader>
              <Link href={'/help'}>Ndihmë</Link>
              <Link href={'kushtet-e-perdorimit'}>Kushtet e përdorimit</Link>
              <Link href={'politikat-e-privatesise'}>Politikat e privatësisë</Link>
             
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>Qëndroni të përditësuar</ListHeader>
              <Stack direction={'row'}>
                <Input
                  placeholder={'E-mail adresa juaj'}
                  bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
                  border={0}
                  _focus={{
                    bg: 'whiteAlpha.300',
                  }}
                />
                <IconButton
                  bg={useColorModeValue('#2CB9FF', '#17153b')}
                  color={useColorModeValue('white', '#17153b')}
                  _hover={{
                    bg: '#17153b',
                  }}
                  aria-label="Subscribe"
                  icon={<BiMailSend />}
                />
              </Stack>
            </Stack>
          </SimpleGrid>
        </Container>
      </Box>
    );
  }
