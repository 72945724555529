
import React from 'react'
import { Box, Text } from '@chakra-ui/react';
function FeaturesCard(props) {
    const {icon,backgroundColor,color,hrefColor,title,content,titleColor} = props;
  return (
    <Box className={`flex  w-auto rounded-2xl   items-center`}  background={`${backgroundColor}`}>
<div className='flex-col p-4 justify-start items-center'>
   {/* <CgFileDocument className='ml-2 mt-2' size={38} color='#2CB9FF'/> */}
   {icon}
   <Text className={`text-white text-lg font-bold text-left pl-3 pt-4`} color={`${titleColor}`}>{title}</Text>
   <Text className={` text-sm font-medium text-left pl-3 pt-4`} color={`${color}`} >{content}</Text>
   <Text className={` text-sm font-medium text-left pl-3 pt-4`} color={`${hrefColor}`}>Lexo më shumë <span aria-hidden="true">&rarr;</span></Text>


</div>
    </Box>
  )
}

export default FeaturesCard