import * as React from "react";
import { Box, ButtonGroup,Flex, Heading} from "@chakra-ui/react";
import { Formik } from "formik";
import {InputControl,SubmitButton} from "formik-chakra-ui";
import * as Yup from "yup";
import axios from 'axios';
import { toast, Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

// IMPORTS ENDS HERE



const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  specialization: "",
  address: '',
  phoneNumber:0,
  experience: 0,
  workPlace: '',

};

const validationSchema = Yup.object({
  firstName: Yup.string().required('Fushë e detyruar'),
  lastName: Yup.string().required('Fushë e detyruar'),
  email: Yup.string().required('Fushë e detyruar').email('Të lutem shënoni në mënyrë të duhur'),
});

const UploadDoctor = () => {
  
    const navigate = useNavigate();
  return (
 
    <Formik
    
      initialValues={initialValues}
      onSubmit={async(values) => {
        try{
          const response = await axios.post('https://pacienti-home.vercel.app/api/doctor/add',values);
          if(response.data.success) {
            toast.success("Perdoruesi u regjistrua me sukses")
            navigate('/')
          }else{
            toast.error("Perdoruesi ekziston")
          }
          }catch(error){
         toast.error('Te lutem provoni perseri')
         console.log(error)
          }
          
      }}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, values, errors, isSubmitting }) => (
         <Flex
         
         minH={'100vh'}
         align={'center'}
         justify={'center'}
         flexDirection={'column'}
       onSubmit={handleSubmit}
      padding={6}
         as="form"
         
         >
          <Toaster/>
           
            <Heading fontSize={'4xl'} textAlign={'center'} color='#39b6ff'>
            Shto një mjek të ri
            </Heading>
            {/* <p>Plotësoni të dhënat në mënyrë që t'ju dërgojmë një postë elektronike<b/> për hapin tjetër të procesit</p> */}
   <Box
   mt={6}
   width={'480px'}
   boxShadow={'lg'}
  p={10}
   >
   
    <Flex direction={'row'}  alignItems={'center'}>
        
          <InputControl  isRequired pr={3} name="firstName" label="Emri" />
          <InputControl isRequired name="lastName" label="Mbiemri" />
         
          </Flex>
          <InputControl  isRequired name="email"  label="E-mail" pt={3} />
          <InputControl  isRequired name="specialization" label="Specializimi" pt={3} />
          <InputControl  isRequired name="workPlace" label="Vendi i punës" pt={3} />
          <InputControl  isRequired name="phoneNumber" label="Numri i telefonit" pt={3} />
          <InputControl  isRequired name="experience" label="Eksperienca" pt={3} />
          <InputControl  isRequired name="isOpen" label="Orari i Punës" pt={3} />
          <InputControl  isRequired itemType="textbox" name="education" label="Edukimi" pt={3} />
          <ButtonGroup spacing={10} pt={8}>
          <SubmitButton
          isLoading={isSubmitting}
                  
                  width={'400px'}
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}>
                 Shto informacionet
                </SubmitButton>
          
          </ButtonGroup>
        
          </Box>
         
        </Flex>
       
      )}
    </Formik>

  );
};

export default UploadDoctor;
