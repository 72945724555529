import React from 'react'
import {Route,Routes} from 'react-router-dom'
import Navbar from './components/Navbar'
import HomeScreen from './pages/HomeScreen'
import Contact from './pages/Contact'
import UploadDoctor from './pages/UploadDoctor'
import DoctorsPage from './pages/DoctorsPage'
import DoctorInfo from './components/DoctorInfo'
import AboutUs from './components/AboutUs'
import TermsOfUse from './pages/TermsOfUse'
import PrivacyPolicy from './pages/PrivacyPolicy'
function App() {
  return (
    <Routes>
  <Route path='/' element={<Navbar/>}>
  <Route index element={<HomeScreen/>}></Route>
  <Route path='contact'  element={<Contact/>}></Route>
  <Route path='doctors'  element={<DoctorsPage/>}></Route>
  <Route path='doctor/:id'  element={<DoctorInfo/>}></Route>
  <Route path='aboutUs'  element={<AboutUs/>}></Route>
  <Route path='kushtet-e-perdorimit'  element={<TermsOfUse/>}></Route>
  <Route path='politikat-e-privatesise'  element={<PrivacyPolicy/>}></Route>
  </Route>
  <Route path='uploadDoctor*192kol1o2kdh35gsga' element={<UploadDoctor/>}/>
 </Routes>
  )
}

export default App