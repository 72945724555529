import React from 'react'
import Footer from '../components/Footer'

function TermsOfUse() {
  return (
    <>
    <div className='flex justify-start items-center flex-wrap'>
<div className='flex mt-12 w-full text-4xl text-darkBlue bg-opacity-30 font-semibold p-14 bg-[#bce0fa] justify-center items-center'>Kushtet e përdorimit</div>
<div className='flex-col  mt-8 items-start justify-start p-8'>
    <h1 className='text-3xl font-bold'>1. Përshkrimi i Platformës</h1>
    <p className='pt-8'>Platforma Pacienti është një aplikacion online që ofron<b> një mjet të menaxhimit të pacientëve për institucionet shëndetësore dhe profesionistët e shëndetit.</b> Platforma ju lejon të shikoni, shtoni, ndryshoni dhe fshini të dhënat e pacientëve dhe të kryeni detyrat e tjera të menaxhimit të pacientëve.</p>
</div>
<div className='flex-col  items-start justify-start p-8'>
    <h1 className='text-3xl font-bold'>2. Regjistrimi dhe përdorimi i platformës</h1>
    <p className='pt-8'>Për të përdorur platformën Pacienti, duhet të regjistroheni dhe të krijoni një llogari. Ju duhet të siguroheni që të dhënat që jepni gjatë regjistrimit janë të sakta dhe të plotë. Ju jeni përgjegjës për të mbajtur konfidenciale të dhënat e llogarisë tuaj dhe për të kryer aktivitetet në platformë në mënyrë të sigurt.</p>
</div>
<div className='flex-col  items-start justify-start p-8'>
    <h1 className='text-3xl font-bold'>3. Të dhënat e pacientëve</h1>
    <p className='pt-8'>Të dhënat e pacientëve janë të lidhura ngushtë me privatësinë dhe konfidencialitetin. Ju duhet të respektoni të gjitha ligjet dhe rregulloret për mbrojtjen e të dhënave personale dhe të shfrytëzoni të dhënat e pacientëve vetëm në mënyrë të lejuar nga ligjet dhe rregulloret e aplikueshme.</p>
</div>
<div className='flex-col  items-start justify-start p-8'>
    <h1 className='text-3xl font-bold'>4. Përdorimi i platformës</h1>
    <p className='pt-8'>Ju pranojini që platforma Pacienti është vetëm për qëllime informative dhe nuk zëvendëson ndonjë këshillë mjekësore profesionale. Ju duhet të mos përdorni platformën për qëllime të paligjshme dhe të mos ndërtoni dhe të mos shpërndani softuer që mund të dëmtojë platformën. Platforma mund të ndryshojë ose të ndërpritë shërbimin në çdo kohë pa paralajmërim.</p>
</div>

<div className='flex-col  items-start justify-start p-8'>
    <h1 className='text-3xl font-bold'>5. Përfshirja e tretës partie</h1>
    <p className='pt-8'>Platforma Pacienti mund të përmbajë lidhje ose referenca për website ose aplikacione të palëve të treta. Ju pranojini se Pacienti nuk mbikëqyron dhe nuk kontrollon këto lidhje dhe nuk është përgjegjës për cilë do informacion ose përmbajtje të ofruar nga këto website ose aplikacione të palëve të treta.</p>
</div>
<div className='flex-col  items-start justify-start p-8'>
    <h1 className='text-3xl font-bold'>6. Ndryshime në kushtet e përdorimit</h1>
    <p className='pt-8'>Pacienti ka të drejtën për të ndryshuar kushtet e përdorimit në çdo kohë. Ndryshimet e kushteve do të publikohen në website dhe do t'ju njoftohen përmes email-it të llogarisë tuaj. Ju pranojini se vazhdimi i përdorimit të platformës pas publikimit të ndryshimeve në kushtet e përdorimit do të konsiderohet si pranim i ndryshimeve të kushteve.</p>
</div>
<div className='flex-col  items-start justify-start p-8'>
    <h1 className='text-3xl font-bold'>7. Limitimi i përgjegjësisë</h1>
    <p className='pt-8'>Pacienti nuk është përgjegjës për dëmet e mundshme që mund të ndodhin si rezultat i përdorimit të platformës Pacienti. Pacienti nuk jep asnjë garanci për funksionimin e platformës dhe përmbajtjen e ofruar në të. Ju përdorni platformën në rrezikun tuaj të vetëm.</p>
</div>
<div className='flex-col  items-start justify-start p-8'>
    <h1 className='text-3xl font-bold'>8. Zbatimi i kushteve</h1>
    <p className='pt-8'>Kushtet e përdorimit janë të mbikëqyruara nga ligjet dhe rregulloret e Republikës së Kosovës. Ju pranojini se çdo mosmarrëveshje ose konflikt që dalin nga përdorimi i platformës do të zgjidhet në mënyrë paqësore në mesin e dy palëve, nëse është e mundur. Në rast se nuk mund të zgjidhet në mënyrë paqësore, çdo mosmarrëveshje do të zgjidhet në gjykatë në mënyrë që është në përputhje me ligjet dhe rregulloret e Republikës së Kosovës.</p>
</div>
<div className='flex-col  items-start justify-start p-8'>
    <h1 className='text-3xl font-bold'>9. Informacione të tjera</h1>
    <p className='pt-8'>Kushtet e përdorimit janë të vlefshme nga data e njoftimit dhe mund të ndryshojnë në çdo kohë pa njoftim paraprak. Nëse keni pyetje apo kërkesa të tjera, ju lutem kontaktoni ekipin tonë të mbështetjes në adresën <b>info@pacientii.com</b></p>
</div>
<div className='flex-col  items-start justify-start p-8'>
    <h1 className='text-3xl font-bold'>10. Mbrojtja e të dhënave personale</h1>
    <p className='pt-8'>Ne mbrojmë të dhënat tuaja personale dhe privatësinë në platformën Pacienti. Të dhënat tuaja personale do të trajtohen në përputhje me <a href='politikat-e-privatesise'><b>Politikën tonë të Privatësisë</b></a>, të cilën mund ta gjeni në website.</p>
</div>
<div className='flex-col  items-start justify-start p-8'>
    <h1 className='text-3xl font-bold'>11. Përdorimi i fjalëve të ndaluara</h1>
    <p className='pt-8'>Në përdorimin e platformës Pacienti është ndaluar përdorimi i fjalëve të ndaluara nga ligjet dhe rregulloret e Republikës së Kosovës. Përdorimi i këtyre fjalëve do të konsiderohet si shkelje e kushteve të përdorimit dhe do të ndërmarrën veprimet e nevojshme siç parashikohet nga ligjet dhe rregulloret e Republikës së Kosovës.</p>
</div>
<div className='flex-col  items-start justify-start p-8'>
    <h1 className='text-3xl font-bold'>12. Njoftimet</h1>
    <p className='pt-8'>Ne mund të dërgojmë njoftime në lidhje me platformën Pacienti dhe shërbimet tona në emailin tuaj të regjistruar. Njoftimet mund të përfshijnë ndryshime në kushtet e përdorimit, ndryshime në Politikën tonë të Privatësisë, apo njoftime tjera të rëndësishme për përdoruesit e platformës. Ju mund të ndaloni pranimin e këtyre njoftimeve nëpërmjet opsioneve të disponueshme në llogarinë tuaj.</p>
</div>
<div className='flex-col  items-start justify-start p-8'>
    <h1 className='text-3xl font-bold'>13. Përfundimi</h1>
    <p className='pt-8'>Ne mund të dërgojmë njoftime në lidhje me platformën tonë dhe shërbimet tona në emailin tuaj të regjistruar. Njoftimet mund të përfshijnë ndryshime në kushtet e përdorimit, ndryshime në Politikën tonë të Privatësisë, apo njoftime tjera të rëndësishme për përdoruesit e platformës. Ju mund të ndaloni pranimin e këtyre njoftimeve nëpërmjet opsioneve të disponueshme në llogarinë tuaj.<br></br><br></br>Efektive që nga: <b>2 Maj 2023</b></p>
</div>
    </div>
    <Footer/>
    </>
  )
}

export default TermsOfUse