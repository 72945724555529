import {
    Box,
    Center,
    Heading,
    Text,
    Stack,
    Image,
  } from '@chakra-ui/react';
  import example from '../assets/example.jpg'
import { Fragment } from 'react';
 
  
  export default function Doctor(props) {
const {specialization,id, firstName, lastName} = props
  return(
      <Fragment key={id}>
      <a href={`doctor/${id}`}>
        <Center py={12}>
        <Box
          role={'group'}
          p={6}
          maxW={'330px'}
          w={'full'}
        
          boxShadow={'2xl'}
          rounded={'xl'}
          pos={'relative'}
          zIndex={1}>
          <Box
            rounded={'lg'}
            mt={-12}
            pos={'relative'}
            height={'230px'}
            _after={{
              transition: 'all .3s ease',
              content: '""',
              w: 'full',
              h: 'full',
              pos: 'absolute',
              top: 5,
              left: 0,
              backgroundImage: {example},
              filter: 'blur(15px)',
              zIndex: -1,
            }}
            _groupHover={{
              _after: {
                filter: 'blur(20px)',
              },
            }}>
            <Image
            pt={20}
              rounded={'lg'}
              height={230}
              width={252}
              objectFit={'cover'}
              src={
                `https://ui-avatars.com/api/?name=${firstName + "+" + lastName}&background=fff&color=39B6FF`
              }
             
            />
          </Box>
          <Stack pt={10} align={'center'}>
            <Text color={'gray.500'} fontSize={'sm'} textTransform={'uppercase'}>
              {specialization}
            </Text>
            <Heading fontSize={'2xl'} fontFamily={'body'} fontWeight={500}>
             Dr.{firstName + " " + lastName}
            </Heading>
            <Stack direction={'row'} align={'center'}>
              <Text fontWeight={'bold'} fontSize={'md'} color={'#2CB9FF'}>
               
              </Text>
              
            </Stack>
          </Stack>
        </Box>
      </Center>
      </a>  
      </Fragment>
    
  )
  }