import { useState } from 'react';
 import axios from 'axios';
  import {toast,Toaster} from 'react-hot-toast'

  export default function Contact() {

const [name,setName] = useState('');
const [email,setEmail] = useState('');
const [message,setMessage] = useState('')
const [lastName,setLastName] = useState('')
const [company,setCompany] = useState('');

const submitContact = async() => {
  try{
   await axios.post('https://pacienti-home.vercel.app/api/doctor/sendContact',{
     email,
     name,
     message,
     lastName,
     company
    }).then((response) =>  toast.success('Mesazhi u dërgua me sukses'))
   
  }catch(error){
    console.log(error);
  }
  
}

    return (
      <div className=" bg-white px-6 py-24 sm:py-32 lg:px-8">
        <Toaster/>
      <div
        className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
        aria-hidden="true"
      >
        <div
          className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#bce0fa] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Keni nevojë për ndihmë?</h2>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          Shkruaj mesazhin dhe ne do t'ë ju përgjigjemi sa më shpejtë
        </p>
      </div>
      <form  className="mx-auto mt-16 max-w-xl sm:mt-20">
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div>
            <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
             Emri
            </label>
            <div className="mt-2.5">
              <input
              onChange={(e) => setName(e.target.value)}
                type="text"
                name="first-name"
                id="first-name"
                required
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
              Mbiemri
            </label>
            <div className="mt-2.5">
              <input
              onChange={(e) => setLastName(e.target.value)}
                type="text"
                name="last-name"
                id="last-name"
                required
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="company" className="block text-sm font-semibold leading-6 text-gray-900">
              Kompania
            </label>
            <div className="mt-2.5">
              <input
              onChange={(e) => setCompany(e.target.value)}
                type="text"
                name="company"
                id="company"
                required
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
             E-mail adresa
            </label>
            <div className="mt-2.5">
              <input
              onChange={(e) => setEmail(e.target.value)}
                type="email"
                name="email"
                id="email"
                required
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
         
          <div className="sm:col-span-2">
            <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
              Mesazhi
            </label>
            <div className="mt-2.5">
              <textarea
              onChange={(e) => setMessage(e.target.value)}
                name="message"
                id="message"
                rows={4}
                required
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={''}
              />
            </div>
          </div>
       
        </div>
        <div className="mt-10">
          <button
          onClick={() => {
            submitContact();
          }}
            type="submit"
            className="block w-full rounded-md bg-skyBlue px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Dërgo mesazhin
          </button>
        </div>
      </form>
    </div>

    );
  }

 


